import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "면회 방침 안내드립니다.",
  "subtitle": "코로니 면회 방침 안내",
  "date": "2022-04-27T00:00:00.000Z",
  "author": "관리자"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`■ 면회 수칙 및 사전예약제 시행 안내 ■`}</h2>
    <h4>{`※ 접촉 면회 시 유의사항 안내`}</h4>
    <hr></hr>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`면회일 기준으로 48시간 이내에 PCR 또는 전문가용 신속항원검사`}</inlineCode></strong>{` `}<inlineCode parentName="p">{`또는 일반
신속항원검사(면회 당일 현장 확인)을 통해 코로나19 음성을 확인하여 주시기 바랍니다.`}</inlineCode></p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`- 음성은 종이증명서, SNS/문자 통지서를 요양병원 직원에게 제출
- 일반신속항원검사 키트는 면회객이 지참 필요
- 확진 후 45일 이내인 경우 PCR, 전문가용 신속항원검사, 일반 신속항원검사 제외
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`마스크(KF94, N95)를 직접 준비하셔서 반드시 착용해 주시기 바랍니다.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`환자, 면회객 모두 아래에 기준에 해당하는 경우 접촉 면회가 가능합니다.`}<br /></p>
      </li>
    </ol>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`구분`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`미확진자`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`기확진자`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`접종차수`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`내용1`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`내용2`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`환자,면회객`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`환자 : 4차접종`}<br />{`면회객 : 2차접종↑`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`면회객`}<br />{` 18세 이상 : 3차 접종`}<br />{` 17세 미만 : 2차 접종`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`환자`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`면회객`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`환자 : 4차접종`}<br />{`면회객 : 내용참조`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`격리해체 후 `}<br />{`① 3일 ~ 90일이내 : 접종력 무관`}<br />{`② 3일이내 또는 90일 경과 : 2차접종`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`면회객`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`환자`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`환자 : 내용참조`}<br />{`면회객 : 2차접종↑`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`격리해체 후 `}<br />{`① 3일 ~ 90일이내 : 접종력 무관`}<br />{`② 3일이내 또는 90일 경과 : 2차접종↑`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`면회객`}<br />{` 18세 이상 : 3차 접종`}<br />{` 17세 미만 : 2차 접종`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`환자,면회객`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`환자 : 내용참조`}<br />{`면회객 : 내용참조`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`격리해체 후 `}<br />{`① 3일 ~ 90일이내 : 접종력 무관`}<br />{`② 3일이내 또는 90일 경과 : 2차접종`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`예시`}</strong></p>
    <ul>
      <li parentName="ul">{`환자 기존 미확진 3차 예방접종 👉 ❌ 대면 면회 불가능. 미확진 환자는 4차 예방접종까지 해야함.`}</li>
      <li parentName="ul">{`18세 이상 기존 미확진자 2차 예방 접종 👉 ❌ 대면 면회 불가능. 18세 이상 미확진자는 3차 예방 접종 이상해야 함.`}</li>
      <li parentName="ul">{`코로나 확진 경험 있는 환자, 면회객의 경우 👉 격리해제 3~90일 사이인 경우 대면면회 가능`}</li>
      <li parentName="ul">{`확진 후 3일 이내 90일 경과한 환자, 면회객의 경우  👉 2차 예방 접종 이상 대면면회 가능`}</li>
      <li parentName="ul">{`면회객과 환자 모두 대면 면회 조건이 맞아야 면회가 가능합니다.`}</li>
    </ul>
    <hr></hr>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`면회객의 코로나19 확진 후 격리해제 여부는 격리해제서를 통해 확인합니다.`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`종이증명서, SNS/문자 통지서 등 확인`}</li>
    </ul>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`면회객의 예방접종 완료 여부는 예방접종증명서*를 통해 확인합니다.`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`종이증명서, 전자증명서(COOV앱), 예방접종스티커 등 확인`}</li>
    </ul>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`면회 전 방문자 명부작성, 체온측정, 손 소독 등에 협조 바랍니다.`}</li>
    </ol>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`코로나19 의심 증상(발열, 인후통, 기침 등), 최근 14일 이내 해외여행력, 확진자 접촉
여부 등 위험요인 확인 시 면회 불가합니다.`}</li>
    </ol>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">
        <p parentName="li">{`면회 도중 마스크를 벗거나 음식섭취는 불가합니다.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`면회객 주의 사항`}</p>
      </li>
    </ol>
    <ul>
      <li parentName="ul"><strong parentName="li"><span style={{
            "color": "red"
          }}>{`"면회예약 필수"`}</span></strong>{` `}<strong parentName="li">{`예약전화 가능시간: 오전10시~오후5시`}</strong>{` `}<strong parentName="li"><a parentName="strong" {...{
            "href": "tel:010-2844-9989"
          }}>{`010-2844-9989`}</a></strong>{`
(예약없이 방문할 경우 면회 불가)`}</li>
      <li parentName="ul">{`접촉면회인원은 4인 이내로 가능하며, `}<em parentName="li">{`추가인원은 면회 불가`}</em>{` (기간 중 1회 면회진행)`}</li>
      <li parentName="ul">{`비접촉면회인원은 2인으로 가능하며, 기간 중 2회 실시하며, 사전에 기간조율`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      